import { Grid, Hidden, ThemeProvider, Typography } from "@material-ui/core";
import React from "react";
import Image from "../components/image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import theme from "../theme";

const OverMijPage = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Over mij" />

        <Typography
          data-aos="fade-right"
          variant="h5"
          style={{ marginBottom: 10, marginTop: 20 }}
        >
          Over Mij
        </Typography>
        <Grid container>
          <Grid item={true} container xs={12} sm={8}>
            <Typography
              data-aos="fade-right"
              variant="subtitle1"
              style={{ marginBottom: 30, marginTop: 40 }}
            >
              Maarten De Bruyne - Ik ben <strong>scheidingscoach</strong> en{" "}
              <strong>familiaal bemiddelaar</strong>. Ik heb jaren ervaring als
              advocaat in het <strong>familierecht</strong> en{" "}
              <strong>familiaal vermogensrecht</strong>. Omdat ik als advocaat
              zag dat procedures voor de familierechtbank op zowel de partners
              als de kinderen vaak fysiek en psychisch zwaar wegen en mensen
              vaak het gevoel hadden dat er niet naar hen werd geluisterd,
              besloot ik om familiaal bemiddelaar te worden.
              <br />
              <br /> Ik ga met beide partners in gesprek om op de best mogelijke
              manier voor de partners en eventuele kinderen een regeling uit te
              werken. Ik heb hierbij oor naar de standpunten van beide partijen
              en probeer deze standpunten met elkaar te verzoenen.
            </Typography>
          </Grid>

          <Grid item={true} container xs={12} sm={4}>
            <div style={{  width: "70%", marginBottom: 20, maxWidth: 300 }}>
              <Image
                alt="Maarten De Bruyne - Profielfoto"
                filename="profile-picture.png"
              />
            </div>
          </Grid>

          <Grid item={true} container xs={12}>
            <Typography
              data-aos="fade-right"
              variant="subtitle1"
              style={{ marginBottom: 30, marginTop: 40 }}
            >
              U kunt voor al uw dossiers, dus zowel inzake een bemiddeling als
              een advisering, rekenen op{" "}
              <strong>
                een empathische, vertrouwelijke en toegankelijke manier van
                werken binnen een professioneel kader
              </strong>
              . Door mijn jarenlange ervaring als familierechtadvocaat kunt u
              bovendien op uw beide oren slapen dat uw dossier op een wettelijk
              sluitende manier wordt behandeld.
            </Typography>
          </Grid>
        </Grid>

        <Hidden smDown={true}>
          <div style={{ width: "100%", height: "50vh" }}></div>
        </Hidden>
      </Layout>
    </ThemeProvider>
  </React.Fragment>
);

export default OverMijPage;
